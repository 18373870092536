import React from 'react';
import { HomePageCompanyLogo, HomePageToolkit, ComponentAccordion, TemplateBackstageLogoIcon, Tool} from '@backstage/plugin-home';
import { LogoFull }  from '../Root/LogoFull';
import { Grid, makeStyles } from '@material-ui/core';
import { Content, Page, InfoCard } from '@backstage/core-components';
import {
      HomePageSearchBar,
    } from '@backstage/plugin-search';
    import { SearchContextProvider } from '@backstage/plugin-search-react';
    import GithubIcon from '../Root/GithubMark';
    import GrafanaIcon from '../Root/Grafana';
    import LogoIcon from '../Root/LogoIcon';

const useLogoStyles = makeStyles(theme => ({
      container: {
        margin: theme.spacing(5, 0),
      }
    }));

const useStyles = makeStyles(theme => ({
  searchBar: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
    border:'none'
  },
  imageIcon: {
    height: '100%'
  },
  iconRoot: {
    textAlign: 'center',
    
  },
  searchBarOutline: {
    borderStyle: 'none'
  }
}));

export const HomePage = () => {

    const { container } = useLogoStyles();
    const classes = useStyles();
    
    const defaultTools: Tool[] = [
        { label: "Github", url:"https://github.com/ibinder", icon: <GithubIcon />},
        { label: "API Design", url:"https://ibinder.stoplight.io/", icon: <TemplateBackstageLogoIcon />},
        { label: "Miro", url:"https://miro.com", icon: <TemplateBackstageLogoIcon />},
    ]

    const operationTools: Tool[] = [
        { label: "Dashboard", url:"https://internal-dashboard.ibinder.com", icon: <GrafanaIcon   />},
        { label: "Cosmos databases", url:"https://cosmos.azure.com", icon: <TemplateBackstageLogoIcon />},
        { label: "Identity Admin", url:"https://identity-admin.ibinder.com", icon: <TemplateBackstageLogoIcon />},
        { label: "iBinder Admin", url:"https://admin.ibinder.com", icon: <LogoIcon />},
        { label: "iBinder Migrations", url:"https://internal-k8s-api.ibinder.com/api/migrations-server/", icon: <LogoIcon />},
    ]

    const testOperationTools: Tool[] = [
        { label: "Dashboard", url:"https://test-internal-dashboard.ibinder.com", icon: <GrafanaIcon   />},
        { label: "Identity Admin", url:"https://test-identity-admin.ibinder.com", icon: <TemplateBackstageLogoIcon />},
        { label: "iBinder", url:"https://test-my.ibinder.com", icon: <LogoIcon />},
        { label: "iBinder Admin", url:"https://test-admin.ibinder.com", icon: <LogoIcon />},
        { label: "iBinder Migrations", url:"https://test-internal-api.ibinder.com/api/migrations-server/", icon: <LogoIcon />},
    ]

    const howOurSystemsWork: Tool[] = [
        { label: "iBinder", url:"https://my.ibinder.com", icon: <LogoIcon />},
        { label: "Helpdesk", url:"https://ibinder.com/en/helpcenter/instructions/", icon: <LogoIcon />},
    ]

  return (
    <SearchContextProvider>
        <Page themeId="home">
            <Content>
                <Grid container justifyContent="center" spacing={6}>
                    <HomePageCompanyLogo
                        className={container}
                            logo={<LogoFull />}
                    />
                    <Grid container item xs={12} >
                    <HomePageSearchBar
                        InputProps={{ classes: { root: classes.searchBar, notchedOutline: classes.searchBarOutline }}}
                        placeholder="Search"
                    />
                    </Grid>

                    <Grid container item xs={12} md={6}>
                        <Grid item xs={12}>                     
                            <HomePageToolkit
                                tools={defaultTools}
                            />                     
                        </Grid>
                    </Grid>                
                <Grid container item xs={12} md={6}>
                    <Grid item xs={12}>
                        <InfoCard title="iBinder Environment links " noPadding  >
                            <Grid item>
                            <HomePageToolkit
                                title="Understand our systems as a user"
                                tools={howOurSystemsWork}
                                Renderer={ComponentAccordion}
                                />
                                <HomePageToolkit
                                title="Operational tools production"
                                tools={operationTools}
                                Renderer={ComponentAccordion}
                                />
                                <HomePageToolkit
                                title="Operational tools test"
                                tools={testOperationTools}
                                Renderer={ComponentAccordion}
                                />
                            </Grid>
                        </InfoCard>
                        </Grid>
                    </Grid>  
                </Grid>
            </Content>
        </Page>
    </SearchContextProvider>
  );
};