import React from 'react';
import { makeStyles } from '@material-ui/core';
import { BackstageTheme } from '@backstage/theme';

const useStyles = makeStyles<BackstageTheme>(
  theme => ({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: theme.palette.navigation.selectedColor,
},
}));

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg viewBox="0 0 136 204" className={classes.svg}
    fill="none"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M127.013 25.022L93.5957 39.8427V3.23847C93.5957 0.896095 91.1863 -0.669059 89.0449 0.282361L3.21607 38.4759C1.25963 39.3442 0 41.2846 0 43.4259V174.955C0 176.794 0.92998 178.506 2.47101 179.506L39.1851 203.289C40.7235 204.286 42.6612 204.436 44.3362 203.691L129.64 165.696C130.808 165.178 131.559 164.018 131.559 162.742V27.9808C131.559 25.6411 129.152 24.0732 127.013 25.022" className={classes.path}></path><path fill-rule="evenodd" clip-rule="evenodd" d="M24.0557 35.1676V53.4295L7.375 42.5619L24.0557 35.1676"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M61.9326 189.903L43.6787 197.992V67.993L61.9326 59.9045V189.903" fill="#4974AC"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M24.9911 157.716V139.202L32.9643 144.396V162.907L24.9911 157.716ZM17.5413 84.1566L10.5518 79.6032V61.092L17.5413 65.6455V84.1566ZM17.5413 118.518L10.5518 113.964V95.4476L17.5413 100.004V118.518ZM17.5413 152.876L10.5518 148.32V129.808L17.5413 134.362V152.876ZM24.9911 104.844L32.9643 110.038V128.552L24.9911 123.355V104.844ZM24.9911 70.4857L32.9643 75.6796V94.1908L24.9911 88.9995V70.4857ZM40.3665 197.429L40.3611 67.784L5.80176 45.2688V174.914L17.3233 182.407V167.386L25.2938 172.58V187.599L40.3665 197.429V197.429Z" fill="#4974AC"></path></g></svg>
 
  );
};

export default LogoIcon;
