import { BackstageOverrides } from '@backstage/core-components';
import { BackstageOverrides as CatalogReactOverrides } from '@backstage/plugin-catalog-react';
import { BackstageTheme, createTheme, palettes } from '@backstage/theme';
import { AutocompleteClassKey } from '@material-ui/lab/Autocomplete';
import { AlertClassKey } from '@material-ui/lab/Alert';
// Labs types not included in overrides; https://github.com/mui/material-ui/issues/19427
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
    MuiAutocomplete: AutocompleteClassKey;
  }
}
const baseTheme = createTheme({
  palette: {
    ...palettes.light,
    primary: {
      main: '#0052CC',
      light: '#4C9AFF',
      dark: '#172B4D',
    },
    secondary: {
      main: '#FF5630',
      light: '#FFAB00',
      dark: '#6554C0',
    },
    grey: {
      50: '#C1C7D0',
      100: '#7A869A',
      200: '#6B778C',
      300: '#5E6C84',
      400: '#505F79',
      500: '#42526E',
      600: '#344563',
      700: '#253858',
      800: '#172B4D',
      900: '#091E42',
    },
    error: {
      main: '#FF5630',
      light: '#FF8F73',
      dark: '#DE350B',
    },
    warning: {
      main: '#FFAB00',
      light: '#FFE380',
      dark: '#FF8B00',
    },
    success: {
      main: '#36B37E',
      light: '#79F2C0',
      dark: '#006644',
    },
    info: {
      main: '#0065FF',
      light: '#4C9AFF',
      dark: '#0747A6',
    },
    text: {
      primary: '#172B48',
      secondary: '#000000',
      
    },
    background: {
      default: '#eaebec',
    },
  },
  fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
  defaultPageTheme: 'home'
});

const createCustomThemeOverrides = (
  theme: BackstageTheme,
): BackstageOverrides & CatalogReactOverrides => {
  return {
    BackstageHeader: {
 
    },
    BackstageContent: {
      root:{
        '& img[alt~="Kroki"]': {
          background:"white"
        }
      }
    },
    BackstageHeaderLabel:{
        root:{
            color: '#000000'
        },
    },
    MuiTab:{
        root:{
            backgroundColor:'#FFFFFF'
        }
    },
    BackstageHeaderTabs: {
      defaultTab: {
        fontSize: 'inherit',
        textTransform: 'none',
      },
      tabRoot:{
        backgroundColor:'#FFFFFF'
      }
    },
    BackstageOpenedDropdown: {
      icon: {
        '& path': {
          fill: '#FFFFFF',
        },
      },
    },
    BackstageTable: {
      root: {
        '&> :first-child': {
          borderBottom: '1px solid #D5D5D5',
          boxShadow: 'none',
        },
        '& th': {
          borderTop: 'none',
          textTransform: 'none !important',
        },
        'td':{
            borderBottom: '0.0625rem solid rgb(193, 202, 211)',
            borderRight: 'none',
            padding: '1rem 0px 1rem 1.1rem',
            maxWidth: '20vw',
            overflowWrap: 'break-word',
            textAlign: 'left',
            width: 'unset',
            fontWeight: 'unset',
            fontSize: '1rem',
            color: 'rgb(52, 57, 65)',
            opacity: '1',
            verticalAlign: 'top',
        }
      },
    },
    MuiTableRow:{
        root:{
            borderBottom: '0.0625rem solid rgb(193, 202, 211)',
            borderRight: 'none',
            padding: '1rem 0px 1rem 1.1rem',
            maxWidth: '20vw',
            overflowWrap: 'break-word',
            textAlign: 'left',
            width: 'unset',
            fontWeight: 'unset',
            fontSize: '1rem',
            color: 'rgb(52, 57, 65)',
            opacity: '1',
            verticalAlign: 'top',
        }
    },
    CatalogReactUserListPicker: {
      title: {
        textTransform: 'none',
      },
    },
    MuiAlert: {
      root: {
        borderRadius: 0,
      },
      standardError: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.error.dark,
        '& $icon': {
          color: '#FFFFFF',
        },
      },
      standardInfo: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.primary.dark,
        '& $icon': {
          color: '#FFFFFF',
        },
      },
      standardSuccess: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.success.dark,
        '& $icon': {
          color: '#FFFFFF',
        },
      },
      standardWarning: {
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.secondary.light,
        '& $icon': {
          color: theme.palette.grey[700],
        },
      },
    },
    MuiAutocomplete: {
      root: {
        '&[aria-expanded=true]': {
          backgroundColor: '#26385A',
          color: '#FFFFFF',
        },
        '&[aria-expanded=true] path': {
          fill: '#FFFFFF',
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 3,
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
      },
    },
    MuiTableHead: {
        root: {
            backgroundColor: '#FFFFFF',
        }
    },
    BackstageContentHeader:{
        container:{
      
        },
    
    },
    
    MuiGrid: {
     
    },
    MuiChip: {
      root: {
        borderRadius: 3,
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.primary.dark,
        margin: 4,
      },
    },
    MuiSelect: {
      root: {
        '&[aria-expanded]': {
          backgroundColor: '#26385A',
          color: '#000000',
        },
      },
    },
    MuiSwitch: {
      root: {
        padding: 10,
      },
      switchBase: {
        padding: 12,
      },
      thumb: {
        backgroundColor: '#FFFFFF',
        height: 14,
        width: 14,
      },
      track: {
        borderRadius: 9,
      },
    },
    MuiTabs: {
      indicator: {
        transition: 'none',
      },
    },
    MuiTypography: {
      button: {
        textTransform: 'none',
      },
    },
  };
};

export const ibinderTheme: BackstageTheme = {
  ...baseTheme,
  overrides: {
    ...baseTheme.overrides,
    ...createCustomThemeOverrides(baseTheme),
  },
};